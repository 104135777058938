<template>
  <b-container class="">
    <RowCol class="text-center m-5 p-5 border bg-light ">
      <h4 class="text-danger font-weight-bold">SƏHİFƏ TAPILMADI</h4>
      <b-button variant="light"
                class="shadow-sm rounded border mt-4 text-dark font-weight-bold"
                @click="handleBack">GERİ DÖN
      </b-button>
    </RowCol>
  </b-container>

</template>
<script>
import RowCol from "@/components/RowCol";

export default {
  name: "PageNotFound",
  components: {RowCol},
  methods: {
    handleBack() {
      if (this.$store.state.username) {
        this.$router.push({name: "Stock"});
      } else {
        this.$router.push({name: "Home"});
      }
    }
  }
};
</script>